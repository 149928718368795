import { FunctionComponent } from 'react';
import Rate from '../Rate/Rate';
import styles from './Testimonial.module.css';
interface TestimonialInfo {
  flag: string;
  rate: number;
  user: string;
  content: string;
}

const Testimonial: FunctionComponent<TestimonialInfo> = ({
  flag,
  rate,
  user,
  content,
}) => {
  return (
    <div className={styles.testimonial}>
      <div className={styles.info}>
        <div className={styles.country}>
          <img src={flag} alt='flag' />
        </div>
        <div className={styles.person}>
          <p className={styles.name}>{user}</p>
          <Rate rate={rate} />
        </div>
      </div>
      <p>{content}</p>
    </div>
  );
};

export default Testimonial;
