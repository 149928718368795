import { Tfeature } from '../Feature/Row/Row';

export const mainfeature: Tfeature[] = [
  {
    title: 'Device Connections',
    col1: '1 Device',
    col2: '5 Device',
    col3: '25 devices, 5 for each family member account',
  },
  {
    title: 'Military-grade encryption',
    col1: 'check',
    col2: 'check',
    col3: 'check',
  },
  {
    title: 'Streaming access',
    col1: 'Optimized for Netflix, YouTube, Hulu, Disney+ and more',
    col2: 'Optimized for Netflix, YouTube, Hulu, Disney+ and more',
    col3: 'Optimized for Netflix, YouTube, Hulu, Disney+ and more',
  },
  {
    title: 'Connection Speed',
    col1: 'Up to 2 Mbps',
    col2: 'Ultra-fast speeds, up to 25 Gbps',
    col3: 'Ultra-fast speeds, up to 25 Gbps',
  },
  {
    title: 'Data Limits',
    col1: 'Unlimited',
    col2: 'Unlimited',
    col3: 'Unlimited',
  },
  {
    title: 'Virtual locations',
    col1: '26+ countries',
    col2: '26+ countries',
    col3: '26+ countries',
  },
  // {
  //   title: 'Internet kill switch',
  //   col1: 'check',
  //   col2: 'check',
  //   col3: 'check',
  // },
  {
    title: 'Cisco protocol',
    col1: 'check',
    col2: 'check',
    col3: 'check',
  },
  {
    title: 'WireGuard protocol',
    col1: 'check',
    col2: 'check',
    col3: 'check',
  },
  {
    title: 'Reality protocol',
    col1: 'check',
    col2: 'check',
    col3: 'check',
  },
  {
    title: 'VLESS protocol',
    col1: 'check',
    col2: 'check',
    col3: 'check',
  },
  {
    title: 'Conciege service',
    col1: 'Support center only',
    col2: '24/7 live chat support',
    col3: '24/7 live chat support',
  },
  // {
  //   title: 'Premium-only devices',
  //   col1: 'cross',
  //   col2: 'Smart TVs, routers, Linux',
  //   col3: 'Smart TVs, routers, Linux',
  // },
];

export const advancedfeature: Tfeature[] = [
  // {
  //   title: 'Malware and phishing protection',
  //   col1: 'cross',
  //   col2: 'check',
  //   col3: 'check',
  // },
  {
    title: 'Smart VPN',
    col1: 'cross',
    col2: 'Coming soon',
    col3: 'Coming soon',
  },
  // {
  //   title: 'DNS Proxy',
  //   col1: 'cross',
  //   col2: 'check',
  //   col3: 'check',
  // },
  {
    title: 'Crowd sharing',
    col1: 'cross',
    col2: 'Coming soon',
    col3: 'Coming soon',
  },
];

export const securityfeature: Tfeature[] = [
  // {
  //   title: 'Blockchain enabled',
  //   col1: 'check',
  //   col2: 'check',
  //   col3: 'check',
  // },
  {
    title: 'Identity theft protection',
    col1: 'check',
    col2: 'check',
    col3: 'check',
  },
  {
    title: 'Spam call blocking with OrbVoice Shield',
    col1: 'cross',
    col2: 'Coming soon',
    col3: 'Coming soon',
  },
];

export const brandDesc: { desc: string }[] = [
  {
    desc: 'OrbTelly is a customised android tv solution which allows streaming services to be in one place. Using OrbTelly the user is able to browse, search, and watch TV & Movies from over 150 services including Netflix, Hulu, HBO, Disney+, Prime Video, Free Services and more. OrbTelly integrates OrbDNS to allow easy management of geo-restrictions.',
  },
  {
    desc: 'OrbRouter is an operating system targeting embedded devices utilising Wi-Fi 6. OrbRouter is a smart framework that ease the Wi-Fi distribution and management with its unique integrated one-click security solution provided via OrbVPN.',
  },
  {
    desc: 'OrbFi is a global WiFi solution allowing the users to stay securely connected with 68 million WiFi hotspots in 180 countries at airports, on airplanes, hotels, train stations, trains, outdoor venues, cafés and more.',
  },
  {
    desc: 'OrbDNS is a cross platform DNS solution built for speed when it comes to special applications such as video and music streaming services. OrbDNS is a smart management platform of multi-geo services though a single network, allowing access to geo-blocked websites or content, while it is not interfering with online traffic or effecting the speed.',
  },
  {
    desc: "OrbCrowd is an intelligent and efficient resource management algorithm powered by AI, it shares the hardware as a resource and utilises the huge user's slack capacity. This allows users to have full control over their contribution and participation in the resource (how much and when). It is an integrated, invisible, plug and play design that has a reward programme in the form of computation token or OrbCoin to credit users. The computing power generated is then sold to corporates and individuals.",
  },
  {
    desc: "OrbPorter support open port to a host on the remote network as long as the OrbVPN tunnel is established. Clients from the Internet can access specific ports through the WAN interface of the OrbSpot or OrbVPN Network, and OrbRouter will forward the traffic over the OrbVPN to the remote network. It's the solution to allow internet access to the servers or resources on the remote network which doesn't have a public IP or its ISP blocks certain port.",
  },
  {
    desc: "OrbMining allows the earning of OrbCoin without having to put down money for it. Using OrbMining, miners receive OrbCoin as a reward for completing 'blocks' of verified transactions which are added to the blockchain. The transactions are distributed via OrbCrowd on the network.",
  },
  {
    desc: 'OrbVPN: Military grade encrypted Virtual Private Network (VPN) hosted by Microsoft Azure. VPNs encrypt the data and online activity, making it impossible for agencies, businesses, hackers, snoops or anyone else who wants to get access to your online activities. Also OrbVPN is the gateway for OrbCrowd and OrbPorter.',
  },
];
