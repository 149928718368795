import Wrapper from '../../components/UI/Wrapper/Wrapper';
import googleplay from '../../components/assets/icons/googleplay.svg';
import applesbadge from '../../components/assets/icons/applesbadge.svg';
import macbadge from '../../components/assets/icons/macbadge.svg';
import rocketp7 from '../../components/assets/technology/Rocket-p7.svg';

import GetStarted from '../../components/UI/GetStartedBtn/GetStarted';

import styles from './DownloadScreen.module.css';

const DownloadScreen = () => {
  return (
    <section className={styles.lastScreen}>
      <Wrapper>
        <GetStarted />
        <div className={styles.downloadWrapper}>
          <div className={styles.downloadContent}>
            <h2 className={`${styles.contentHeader}`}>
              Download the <span className={styles.brand}>OrbVPN</span> Now!
            </h2>
            <div className={styles.rocketParts}>
              <img src={rocketp7} alt='Download the OrbVPN' />
            </div>
            <p className={styles.worldendp}>
              Join tousands of users already enjoying Internet Freedom, high
              grade of data protection and online security around the world.
            </p>
            <p className={styles.worldendp}>
              To purchase a subscription — whether Basic, Premium, or Premium
              Family — simply install the OrbVPN app on your device. Once
              installed, you can subscribe directly through the app, with
              payments securely managed via the Apple App Store or Google Play.
            </p>
            <div className={styles.badges}>
              <a href='https://apps.apple.com/us/app/orbvpn/id6727010164'>
                <img src={applesbadge} alt='Download from apple store' />
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.orbvpn.android&hl=en_US&gl=US&pli=1'>
                <img src={googleplay} alt='Download from google play' />
              </a>
              <a href='https://apps.apple.com/us/app/orbvpn/id6727010164'>
                <img src={macbadge} alt='Download for your Mac' />
              </a>
              {/* <a href='https://play.google.com/store/apps/details?id=com.orb_vpn&hl=en_US&gl=US&pli=1'>
                    <img src={microsoftbadge} alt='Download for your windows' />
                  </a> */}
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};

export default DownloadScreen;
