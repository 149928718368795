import { FunctionComponent } from 'react';
import NavigationItem from '../NavigationItem/NavigationItem';
import styles from './mobileNavigation.module.css';

interface IMobileNav {
  isOpen: boolean;
}

const MobileNavigation: FunctionComponent<IMobileNav> = ({ isOpen }) => {
  return (
    <ul className={`${styles.mobileNav} ${isOpen ? styles.opened : ''}`}>
      <NavigationItem path='/pricing' link='Pricing' />
      <NavigationItem path='/technology' link='Technology' />
      <NavigationItem path='/spot' link='Spot' />
      <NavigationItem path='/company' link='Company' />
      <NavigationItem path='/blog' link='Blog' />
      <NavigationItem path='https://docs.orbvpn.com' link='Resources' />
      <NavigationItem path='https://orbvpn.xyz/auth/signin' link='Login' />
      <NavigationItem path='/pricing' link='Get Started' isButton />
    </ul>
  );
};

export default MobileNavigation;
