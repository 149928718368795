import React, { useEffect } from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'; // Import BrowserRouter
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import HomeScreens from './screens/HomeScreens/HomeScreens';
import PriceScreens from './screens/PriceSreens/PriceScreens';
import TechnologyScreen from './screens/TechnologyScreen/TechnologyScreen';
import DownloadScreen from './screens/DownloadScreen/DownloadScreen';
import SpotScreens from './screens/SpotScreens/SpotScreens';
import CompanyScreens from './screens/CompanyScreens/CompanyScreens';
import Layout from './components/Layout/Layout';
import ScrollToTop from './hooks/ScrollToTop';
import Policies from './screens/Policies/Policies';
import TermsConditions from './screens/Policies/TermsConditions';

// Initialize GA4 once when the app is mounted
ReactGA.initialize('G-ZPQ1RQW8C4'); // Replace with your GA4 Measurement ID

const App = () => {
  useEffect(() => {
    // Track the initial page load
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <Layout>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<HomeScreens />} />
        <Route path='/pricing' element={<PriceScreens />} />
        <Route path='/technology' element={<TechnologyScreen />} />
        <Route path='/download' element={<DownloadScreen />} />
        <Route path='/spot' element={<SpotScreens />} />
        <Route path='/company' element={<CompanyScreens />} />
        <Route path='/privacy-policy' element={<Policies />} />
        <Route path='/terms-conditions' element={<TermsConditions />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Layout>
  );
};

// Wrap App inside BrowserRouter and render it
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

export default App;
