import { useState } from 'react';
import Wrapper from '../../components/UI/Wrapper/Wrapper';
import Row from '../../components/Feature/Row/Row';
import styles from './PriceScreen.module.css';
import sun from '../../components/assets/sun.svg';
import birds from '../../components/assets/threebirds.svg';
import {
  advancedfeature,
  mainfeature,
  securityfeature,
} from '../../components/constants';

const PriceScreens = () => {
  //  const userSelectRedirectRoute = '/panel/register';
  const [basic, setBasic] = useState(false);
  const [premium, setPremium] = useState(false);
  const [prefamily, setPrefamily] = useState(false);
  const [isMonthly, setIsMonthly] = useState(false);

  return (
    <>
      <section className={styles.showcase}>
        <Wrapper overflowHidden={false}>
          <div className={styles.sun}>
            <img src={sun} alt='Sun Smiling' />
          </div>
          <div className={styles.birds}>
            <img src={birds} alt='Birds' />
          </div>
          <div className={styles.plan}>
            <div className={styles.planContent}>
              <h1 className={styles.headL}>Select your VPN Plan</h1>
              <p className={styles.moneyback}>
                <span>Try OrbVPN risk-free.</span>
                All plans come with a 3-days money-back guarantee.
              </p>
            </div>
            <div className={styles.action}>
              <div className={styles.price}>
                <p
                  className={`${styles.yearly} ${
                    isMonthly ? styles.passive : ''
                  }`}
                >
                  YEARLY
                </p>
                <small className={`${isMonthly ? styles.passive : ''}`}>
                  Save up to 51%
                </small>
              </div>
              <div>
                <div className={styles.input}>
                  <input
                    type='checkbox'
                    checked={isMonthly}
                    onChange={() => setIsMonthly((prev) => !prev)}
                  ></input>
                </div>
                <small></small>
              </div>
              <div className={styles.price}>
                <p
                  className={`${styles.monthly} ${
                    !isMonthly ? styles.passive : ''
                  }`}
                >
                  MONTHLY
                </p>
                <small></small>
              </div>
            </div>
            <div className={styles.pricetable}>
              <div className={styles.box}>
                <h2 className={styles.boxhead}>
                  Basic
                  {!isMonthly ? <small>Save 51%</small> : null}
                </h2>
                <div className={styles.boxtext}>
                  <span className={styles.boxPrice}>
                    $ {isMonthly ? 3.99 : 1.75}/mo
                  </span>
                  <p>
                    For fast, secure, and private browsing with top-tier
                    protection.
                  </p>
                </div>
                <div className={styles.mobileSide}>
                  <button
                    className={styles.readMore}
                    onClick={() => {
                      setBasic((prevBasic) => !prevBasic);
                    }}
                  >
                    Read more...
                  </button>
                  <div
                    className={`${styles.mainwrapper} ${
                      !basic ? styles.deactive : ''
                    }`}
                  >
                    <Row featureList={mainfeature} isMobile group='basic' />
                    <Row featureList={advancedfeature} isMobile group='basic' />
                    <Row featureList={securityfeature} isMobile group='basic' />
                  </div>
                </div>
                <a
                  href={
                    // isMonthly
                    // ? 'https://buy.stripe.com/28o14T3Ipa5Ncmc5kq'
                    // : 'https://buy.stripe.com/aEU3d17YFcdV1Hy003'
                    '/download'
                  }
                  className={`${styles.btn} ${styles.btnWhite}`}
                >
                  Select Basic
                </a>
              </div>
              <div className={styles.box}>
                <h2 className={styles.boxhead}>
                  Premium
                  {!isMonthly ? <small>Save 41%</small> : null}
                </h2>
                <div>
                  <span className={styles.boxPrice}>
                    $ {isMonthly ? 6.99 : 4.09}/mo
                  </span>
                  <p className={styles.boxtext}>
                    Enjoy lightning-fast browsing and seamless streaming on
                    platforms like Netflix, Disney+, and more, all with enhanced
                    security features and user-friendly apps.
                  </p>
                </div>
                <div className={styles.mobileSide}>
                  <button
                    className={styles.readMore}
                    onClick={() => {
                      setPremium((prevPremium) => !prevPremium);
                    }}
                  >
                    Read more...
                  </button>
                  <div
                    className={`${styles.mainwrapper} ${
                      !premium ? styles.deactive : ''
                    }`}
                  >
                    <Row featureList={mainfeature} isMobile group='premium' />
                    <Row
                      featureList={advancedfeature}
                      isMobile
                      group='premium'
                    />
                    <Row
                      featureList={securityfeature}
                      isMobile
                      group='premium'
                    />
                  </div>
                </div>
                <a
                  href={'/download'}
                  // isMonthly
                  //   ? 'https://buy.stripe.com/14k14TceV7XFgCs7sx'
                  //   : 'https://buy.stripe.com/fZefZN2EldhZgCs7su'
                  // }
                  className={styles.btn}
                >
                  Select Premium
                </a>
              </div>
              <div className={styles.box}>
                <h2 className={styles.boxhead}>
                  Premium Family
                  {!isMonthly ? <small>Save 45%</small> : null}
                </h2>
                <div className={styles.boxtext}>
                  <span className={styles.boxPrice}>
                    $ {isMonthly ? 11.99 : 6.59}/mo
                  </span>
                  <p>
                    Comprehensive premium protection for the entire family,
                    covering up to 25 devices.
                  </p>
                </div>
                <div className={styles.mobileSide}>
                  <button
                    className={styles.readMore}
                    onClick={() => {
                      setPrefamily((prevPrefamily) => !prevPrefamily);
                    }}
                  >
                    Read more...
                  </button>
                  <div
                    className={`${styles.mainwrapper} ${
                      !prefamily ? styles.deactive : ''
                    }`}
                  >
                    <Row featureList={mainfeature} isMobile group='prefamily' />
                    <Row
                      featureList={advancedfeature}
                      isMobile
                      group='prefamily'
                    />
                    <Row
                      featureList={securityfeature}
                      isMobile
                      group='prefamily'
                    />
                  </div>
                </div>
                <a
                  href={'/download'}
                  //   isMonthly
                  //     ? 'https://buy.stripe.com/6oE3d1en3fq7fyoaEI'
                  //     : 'https://buy.stripe.com/eVadRFfr74LtdqgeUV'
                  // }
                  className={styles.btn}
                >
                  Select Premium Family
                </a>
              </div>
            </div>
          </div>
        </Wrapper>
      </section>

      {/* Main Features */}
      <section className={styles.main}>
        <Wrapper>
          <div className={styles.mainwrapper}>
            <Row featureList={mainfeature} />
          </div>
          <h3 className={styles.headtitle}>Advanced features</h3>
          <div className={styles.mainwrapper}>
            <Row featureList={advancedfeature} />
          </div>
          <h3 className={styles.headtitle}>Security suite features</h3>
          <div className={styles.mainwrapper}>
            <Row featureList={securityfeature} />
          </div>
        </Wrapper>
      </section>
    </>
  );
};

export default PriceScreens;
