import { useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import Wrapper from '../../components/UI/Wrapper/Wrapper';
import styles from './HomeScreen.module.css';
import amazonLogo from '../../components/assets/brands/amazon_logo.svg';
import amazonprime from '../../components/assets/brands/amazonprime.svg';
import appletv from '../../components/assets/brands/appletv.svg';
import disney from '../../components/assets/brands/disney.svg';
import hbo from '../../components/assets/brands/hbo.svg';
import netflix from '../../components/assets/brands/netflix.svg';
import youtube from '../../components/assets/brands/youtube.svg';
import sun from '../../components/assets/sun.svg';
import buildbg from '../../components/assets/buildimg.svg';
import gamerbg from '../../components/assets/gamerbg.svg';
import landbg from '../../components/assets/landingrocket.svg';
import landbg2 from '../../components/assets/landbgmobile.svg';
import twobg from '../../components/assets/twobgd.svg';
import twobgm from '../../components/assets/twobgm.svg';
import savebg from '../../components/assets/savebg.svg';
import savebg2 from '../../components/assets/savebg2.svg';
import showcasebottom from '../../components/assets/showcasebottom.svg';
import showcasebottom2 from '../../components/assets/showcasebottom-mobile.svg';
import happyend from '../../components/assets/happyend.svg';
import hifriend from '../../components/assets/hifriend.svg';
import friend from '../../components/assets/friend.svg';
import connectbg from '../../components/assets/connectbg.svg';
import connectbg2 from '../../components/assets/connectbg2.svg';
import connectgirl from '../../components/assets/connectgirl.svg';
import connectman from '../../components/assets/connectman.svg';
import connectphone from '../../components/assets/connectphone.svg';
import staticip from '../../components/assets/icons/staticip.svg';
import virusfilter from '../../components/assets/icons/virusfilter.svg';
import multilogin from '../../components/assets/icons/multilogin.svg';
import bandwidth from '../../components/assets/icons/bandwidth.svg';
import autosecure from '../../components/assets/icons/autosecure.svg';
import autoconnect from '../../components/assets/icons/autoconnect.svg';
import worldend from '../../components/assets/worldend.svg';
import worldend2 from '../../components/assets/worldend2.svg';
import encryption from '../../components/assets/icons/encryption.svg';
import googleplay from '../../components/assets/icons/googleplay.svg';
import applesbadge from '../../components/assets/icons/applesbadge.svg';
import macbadge from '../../components/assets/icons/macbadge.svg';
// import microsoftbadge from '../../components/assets/icons/microsoftbadge.svg';
import Testimonial from '../../components/UI/Testimonial/Testimonial';
import GetStarted from '../../components/UI/GetStartedBtn/GetStarted';
import usa from '../../components/assets/country/usa.svg';
import spain from '../../components/assets/country/spain.svg';
import australia from '../../components/assets/country/australia.svg';
import germany from '../../components/assets/country/germany.svg';
import japan from '../../components/assets/country/japan.svg';
import italy from '../../components/assets/country/italy.svg';
import russia from '../../components/assets/country/russia.svg';
import brazil from '../../components/assets/country/brazil.svg';
import uae from '../../components/assets/country/uae.svg';
import uk from '../../components/assets/country/uk.svg';
import france from '../../components/assets/country/france.svg';
import mexico from '../../components/assets/country/mexico.svg';
import china from '../../components/assets/country/china.svg';
import south_africa from '../../components/assets/country/south_africa.svg';
import sweden from '../../components/assets/country/sweden.svg';
import greece from '../../components/assets/country/greece.svg';
import canada from '../../components/assets/country/canada.svg';
import saudi_arabia from '../../components/assets/country/saudi_arabia.svg';
import argentina from '../../components/assets/country/argentina.svg';
import india from '../../components/assets/country/india.svg';
import portugal from '../../components/assets/country/portugal.svg';
import norway from '../../components/assets/country/norway.svg';
import egypt from '../../components/assets/country/egypt.svg';
import new_zealand from '../../components/assets/country/new_zealand.svg';
import turkey from '../../components/assets/country/turkey.svg';
import denmark from '../../components/assets/country/denmark.svg';
import singapore from '../../components/assets/country/singapore.svg';
import iran from '../../components/assets/country/iran.svg';

const HomeScreens = () => {
  const [vWidth, setVWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const updateSize = () => {
      setVWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <section className={styles.showcase}>
        <Wrapper>
          <GetStarted />
          <div className={styles.sun}>
            <img src={sun} alt='Sun Smiling' />
          </div>

          <div className={styles.showcaseWrapper}>
            <div className={styles.showcaseContent}>
              <h1 className={styles.contentTitle}>
                Blazing Fast & Secure VPN on
                <span>
                  <img
                    src={amazonLogo}
                    className={styles.amazon}
                    alt='Microsoft Azure'
                  />
                </span>
              </h1>
              <p className={styles.titlePlead}>
                Protect your data, block threats, stop trackers, and stay
                anonymous — hosted on Microsoft Azure’s reliable and secure
                infrastructure.
              </p>
              <div className={styles.action}>
                <small className={styles.actionL}>
                  Just from 1.75$ per month
                </small>
                <Link to='/pricing' className={styles.actionBtn}>
                  Get OrbVPN
                </Link>
                <small className={styles.actionS}>
                  3 days money-back guarantee
                </small>
              </div>
            </div>
          </div>
          <div className={styles.showcaseBottom}>
            <img
              src={vWidth > 768 ? showcasebottom : showcasebottom2}
              alt='something happened'
            />
          </div>
        </Wrapper>
      </section>
      <section className={styles.featureSection}>
        <Wrapper>
          <div className={styles.featureWrapper}>
            <div className={styles.feature}>
              <img src={autosecure} alt='Automatic Security feature' />
              <p>Automatic Security</p>
            </div>
            <div className={styles.feature}>
              <img src={staticip} alt='Static Ip feature' />
              <p>Static Ip</p>
            </div>
            <div className={styles.feature}>
              <img src={virusfilter} alt='Virus Filter feature' />
              <p>Virus Filtering</p>
            </div>
            <div className={styles.feature}>
              <img src={multilogin} alt='Multi Logins Feature' />
              <p>Multi Logins</p>
            </div>
            <div className={styles.feature}>
              <img src={encryption} alt='FIPS Encryption Feature' />
              <p>FIPS Encryption</p>
            </div>
            <div className={styles.feature}>
              <img src={bandwidth} alt='Unlimited Bandwidth Feature' />
              <p>Unlimited Bandwidth</p>
            </div>
            <div className={styles.feature}>
              <img src={autoconnect} alt='Auto Connection Feature' />
              <p>Auto Connection</p>
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.worldEnd}>
        <Wrapper>
          <div className={styles.worldEndWrapper}>
            <div className={styles.worldEndContent}>
              <GetStarted />
              <h2 className={`${styles.contentHeader} ${styles.white}`}>
                Protect your online activity
              </h2>
              <p className={styles.worldendp}>
                Enhance the security of your identity, health, finance, family
                data when searching, browsing, shopping, using dating apps, and
                communicating.
              </p>
              <p className={styles.worldendp}>
                We encrypt all your data and keep no records of your individual
                activities online.
              </p>
              <p className={styles.worldendp}>
                You could instantly enjoy our sleek and seamless apps for
                Windows, Mac, iOS and Android.
              </p>
              <p className={styles.worldendp}>
                We’ve designed our apps with you in mind for a flawless
                experience.
              </p>
              <div className={styles.badges}>
                <a href='https://apps.apple.com/us/app/orbvpn/id6727010164'>
                  <img src={applesbadge} alt='Download from apple store' />
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.orbvpn.android&hl=en_US&gl=US&pli=1'>
                  <img src={googleplay} alt='Download from google play' />
                </a>
                <a href='https://apps.apple.com/us/app/orbvpn/id6727010164'>
                  <img src={macbadge} alt='Download for your Mac' />
                </a>
                {/* <a href='https://play.google.com/store/apps/details?id=com.orb_vpn&hl=en_US&gl=US&pli=1'>
                  <img src={microsoftbadge} alt='Download for your windows' />
                </a> */}
              </div>
            </div>
            <div className={styles.worldEndSvg}>
              <img
                className={styles.worldendSVG}
                src={vWidth > 768 ? worldend : worldend2}
                alt="if you don't buy orbvpn, the world will end!"
              />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.worldEnd3}>
        <Wrapper>
          <div className={styles.topMenSvg}>
            <img className={styles.hifriend} src={friend} alt='My friend' />
          </div>
          <div className={styles.worldEndWrapper}>
            <div className={styles.worldEndContent}>
              <GetStarted />
              <h2 className={`${styles.contentHeader} ${styles.white}`}>
                High quality streaming experience
              </h2>
              <p className={styles.worldendp}>
                Say goodbye to buffering delays and enjoy a high-quality
                streaming experience.
              </p>
              <p className={styles.worldendp}>
                With OrbVPN, you can browse and explore content from across the
                globe, enhancing your online privacy and security while
                maintaining a smooth connection, regardless of your location.
              </p>
              <div className={styles.badges2}>
                <img src={netflix} alt='Netflix' />
                <img src={youtube} alt='Youtube' />
                <img src={appletv} alt='Apple tv' />
              </div>
              <div className={styles.badges2}>
                <img src={amazonprime} alt='Amazon Prime' />
                <img src={disney} alt='Disney' />
                <img src={hbo} alt='HBO' />
              </div>
            </div>
            <div className={styles.hifriendSVG}>
              <img src={hifriend} alt='Friends can be meet!' />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.connect}>
        <Wrapper>
          <div className={styles.connectWrapper}>
            <div className={styles.boxes}>
              <div className={styles.connectBox}>
                <GetStarted />
                <div className={styles.boximg}>
                  <img src={connectgirl} alt='girl connecting' />
                </div>
                <div className={styles.boxcontent}>
                  <h3 className={styles.contentTitle}>World with no borders</h3>
                  <p className={styles.contentpf}>
                    Secure access to free and open internet.
                  </p>
                  <p className={styles.contentp}>
                    Enjoy secure, unrestricted access to the internet wherever
                    you are. Safely connect to your favorite content, social
                    networks, news, and streaming services without regional
                    limitations or censorship.
                  </p>
                </div>
              </div>
              <div className={styles.connectBox}>
                <div className={styles.boximg}>
                  <img src={connectphone} alt='phone connecting' />
                </div>
                <div className={styles.boxcontent}>
                  <h3 className={styles.contentTitle}>#1 VPN technology</h3>
                  <p className={styles.contentpf}>
                    We incorporate the #1 and up to date technologies.
                  </p>
                  <p className={styles.contentp}>
                    We use cutting-edge VPN technology that meets the highest
                    industry standards. Our protocols, including SSL, are
                    trusted by world’s largest organizations and ensure your
                    data stays protected.
                  </p>
                </div>
              </div>
              <div className={styles.connectBox}>
                <div className={styles.boximg}>
                  <img src={connectman} alt='man connecting' />
                </div>
                <div className={styles.boxcontent}>
                  <h3 className={styles.contentTitle}>Protect your privacy</h3>
                  <p className={styles.contentpf}>
                    Your gateway to secure, unrestricted internet.
                  </p>
                  <p className={styles.contentp}>
                    Maintain your privacy with secure access to an open
                    internet. Bypass tracking, allowing you to browse, connect,
                    and stream privately, without intrusive surveillance or
                    targeted ads.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.connectbg}>
              <img
                src={vWidth > 768 ? connectbg : connectbg2}
                alt='background img'
              />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.build}>
        <Wrapper>
          <div className={styles.buildWrapper}>
            <div className={styles.buildContent}>
              <GetStarted />
              <h2 className={styles.contentHeader}>
                OrbCrowd: Future Decentralized Access to High-Performance
                Computing*
              </h2>
              <p className={styles.buildp}>
                We plan to explore the development of an AI-powered resource
                management system that could enhance the efficiency and sharing
                of hardware. This concept is part of our forward-looking
                strategy and is aligned with emerging technologies, including
                potential applications of quantum computing.
              </p>
              <p className={styles.actionS}>
                * While this feature is not currently available, our company
                revenue may allow us to implement and expand on this concept.
                Any decisions to introduce such features will be made at the
                company’s discretion based on our ongoing priorities and
                development goals.
              </p>
            </div>
            <div className={styles.dummy}></div>
            <div className={styles.buildSvg}>
              <img src={buildbg} alt='Friends can be meet!' />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.landing}>
        <Wrapper>
          <div className={styles.landingWrapper}>
            <div className={styles.landingContent}>
              <GetStarted />
              <h2 className={styles.contentHeader}>
                Blazing Speed &amp; Superior Security
              </h2>
              <p className={styles.worldendp}>
                Browse with lightning fast connection speeds and military grade
                intelligence agencies level encryption wherever you are
              </p>
              <p className={styles.worldendp}>
                Connect to OrbVPN's global, high-speed servers hosted by
                Microsoft Azure and surf the web free of throttling, download
                caps or bandwidth limits
              </p>
              <p className={styles.worldendp}>
                We manage our own private VPN network and write our own code to
                provide you with the fastest, most secure experience possible.
              </p>
            </div>

            <div className={styles.landingbg}>
              <img src={landbg} alt='landing background' />
            </div>
          </div>
        </Wrapper>
        <div className={styles.landingbgmobile}>
          <img src={landbg2} alt='landing background' />
        </div>
      </section>

      <section className={styles.landing}>
        <Wrapper>
          <div className={styles.buildWrapper}>
            <div className={styles.landingContent}>
              <GetStarted />
              <h2 className={styles.contentHeader}>
                Elevate Your Gaming Experience with OrbVPN
              </h2>
              <p className={styles.buildp}>
                OrbVPN optimizes your gaming connection, reducing lag and
                latency for smoother gameplay and faster response times. Whether
                you’re competing online or playing casually, our high-speed
                servers ensure an uninterrupted experience.
              </p>
              <p className={styles.buildp}>
                Stay ahead of the competition by protecting your gaming sessions
                from DDoS attacks and unwanted disruptions. With OrbVPN, you get
                secure and private access to game servers worldwide, unlocking
                the ultimate freedom for global multiplayer gaming.
              </p>
            </div>
            <div className={styles.dummy}></div>
            <div className={styles.GamerSvg}>
              <img src={gamerbg} alt='Friends can be meet!' />
            </div>
          </div>
        </Wrapper>
      </section>

      <section className={styles.worldEnd3}>
        <Wrapper>
          <div className={styles.landingWrapper}>
            <div className={styles.cryptoContent}>
              <GetStarted />
              <h2 className={styles.contentHeader}>
                Protect your crypto investment
              </h2>
              <p className={styles.worldendp}>
                OrbVPN adds an extra shelter of security in the transaction
                process, protecting your cryptocurrencies investments from
                hackers.
              </p>
              <p className={styles.worldendp}>
                OrbVPN also enables secure and private access to crypto
                platforms, ensuring that your activities remain protected,
                regardless of where you’re located.
              </p>
            </div>
            <div className={styles.twobg}>
              <img src={twobg} alt='landing background' />
            </div>
            <div className={styles.twobgm}>
              <img src={twobgm} alt='landing background' />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.worldEnd3}>
        <Wrapper>
          <div className={styles.testimonialWrapper}>
            <GetStarted />
            <div className={styles.testimonials}>
              <small className={styles.actionS}>
                {
                  'The testimonials presented on this website are for illustrative purposes only and may be based on user feedback, simulated experiences, or representative examples of customer experiences. These reviews may have been edited or generated to fit the context of our service. We do not guarantee that these reviews are the original submissions of the respective individuals. Any resemblance to real persons or actual customer feedback is purely coincidental. The opinions expressed in the testimonials do not necessarily reflect the views of OrbVPN or its affiliates. Please use your own discretion when interpreting these reviews.'
                }
              </small>
              <br />
              <br />
              <br />

              <Testimonial
                flag={usa}
                rate={5}
                user={'John D. (USA)'}
                content={
                  "I've been using OrbVPN for about six months now, and it's been a game changer for my online security. Whether I'm working from a coffee shop or on the go, I know my connection is secure. It's easy to use and I haven't experienced any issues with speed. Highly recommended!"
                }
              />

              <Testimonial
                flag={spain}
                rate={5}
                user={'Sofia R. (Spain)'}
                content={
                  "OrbVPN has been fantastic for keeping my data private when I'm traveling. I'm often in different countries for work, and I love that I can rely on OrbVPN to access the internet safely. The setup was quick, and the service has been flawless so far!"
                }
              />

              <Testimonial
                flag={australia}
                rate={5}
                user={'Liam M. (Australia)'}
                content={
                  'I was skeptical at first, but OrbVPN has exceeded my expectations. The speeds are great, and I feel much more secure knowing my financial information is protected, especially when I’m managing my crypto wallets. A must-have for anyone serious about privacy.'
                }
              />

              <Testimonial
                flag={germany}
                rate={5}
                user={'Mia L. (Germany)'}
                content={
                  "I use OrbVPN mainly for streaming and it's been super reliable. I can access all my favorite shows without worrying about privacy. The interface is simple, and I appreciate how responsive customer support has been whenever I had questions. Great service!"
                }
              />

              <Testimonial
                flag={japan}
                rate={5}
                user={'Kenji H. (Japan)'}
                content={
                  "As someone who values privacy, OrbVPN has become essential for me. It's fast, reliable, and most importantly, secure. I've tried a few other VPNs before, but none have matched the overall quality of OrbVPN. It's been a perfect fit for my needs."
                }
              />

              <Testimonial
                flag={italy}
                rate={5}
                user={'Isabella C. (Italy)'}
                content={
                  "I’ve been using OrbVPN for both work and personal browsing, and it’s been fantastic. My connection stays fast even with the extra security, and I can confidently handle sensitive information online. It's a great peace of mind to have, especially when traveling."
                }
              />

              <Testimonial
                flag={russia}
                rate={5}
                user={'Alexei P. (Russia)'}
                content={
                  'OrbVPN has been an excellent choice for protecting my online activity. I use it every day, and the performance is impressive—fast and secure. The app is also very user-friendly, making it easy to protect myself from hackers and stay anonymous online.'
                }
              />

              <Testimonial
                flag={brazil}
                rate={5}
                user={'Lucas B. (Brazil)'}
                content={
                  'I wasn’t sure if I needed a VPN, but after using OrbVPN, I won’t go back! It’s so simple to use, and I love the added security when shopping online or managing my bank accounts. No issues with speed or service, which is a big plus.'
                }
              />

              <Testimonial
                flag={uae}
                rate={5}
                user={'Amira K. (UAE)'}
                content={
                  'OrbVPN is a must-have for anyone concerned about online privacy. I was worried about my personal data being exposed, especially with all the cyber threats nowadays. Since using OrbVPN, I feel much safer online. It’s fast, reliable, and I love how easy it is to use.'
                }
              />

              <Testimonial
                flag={uk}
                rate={5}
                user={'Thomas J. (UK)'}
                content={
                  'Fantastic VPN service! I use OrbVPN across all my devices, and it’s been seamless. It keeps me safe when browsing, and I’ve noticed no drop in connection speeds. I’ve recommended it to friends, and they love it too. Definitely worth the investment!'
                }
              />

              <Testimonial
                flag={france}
                rate={5}
                user={'Nadine L. (France)'}
                content={
                  'OrbVPN has been my go-to solution for online security. As someone who values privacy, I’m happy to say that it performs flawlessly, even with streaming and downloads. The peace of mind I get knowing my data is secure is worth every penny.'
                }
              />

              <Testimonial
                flag={mexico}
                rate={5}
                user={'Miguel S. (Mexico)'}
                content={
                  'I started using OrbVPN after a friend recommended it, and I’ve been super impressed. The app is really intuitive, and I love that I can protect all my devices with one account. Great speeds, no interruptions—it’s everything I was looking for!'
                }
              />

              <Testimonial
                flag={china}
                rate={5}
                user={'Chen W. (China)'}
                content={
                  'I travel often for work, and OrbVPN has made a huge difference for me. It works perfectly no matter where I am, and I feel much more secure accessing important files and emails on public Wi-Fi. Definitely a reliable service for frequent travelers.'
                }
              />

              <Testimonial
                flag={south_africa}
                rate={5}
                user={'Amina T. (South Africa)'}
                content={
                  'I’ve tried a few VPNs before, but none have been as smooth as OrbVPN. I’m so impressed with how easy it is to set up and use. Plus, it doesn’t slow down my internet connection at all, which was my main concern with other VPNs I tried.'
                }
              />

              <Testimonial
                flag={sweden}
                rate={5}
                user={'Sven H. (Sweden)'}
                content={
                  'Since switching to OrbVPN, I’ve noticed a big improvement in my online privacy. It’s quick, easy to use, and gives me confidence that my data is protected. I especially appreciate the great customer support whenever I have a question!'
                }
              />

              <Testimonial
                flag={greece}
                rate={5}
                user={'Elena P. (Greece)'}
                content={
                  "OrbVPN has been amazing for keeping my data secure. I love the fast connection, especially when I’m streaming or working remotely. It's easy to use, and I never worry about my privacy anymore. Highly recommended!"
                }
              />

              <Testimonial
                flag={canada}
                rate={5}
                user={'David R. (Canada)'}
                content={
                  'What I love most about OrbVPN is that I can set it up on all my devices with just one account. It’s fast, reliable, and keeps my information safe from prying eyes, whether I’m working or browsing. A fantastic VPN service that I’ll continue using.'
                }
              />

              <Testimonial
                flag={saudi_arabia}
                rate={5}
                user={'Fatima B. (Saudi Arabia)'}
                content={
                  'OrbVPN is a lifesaver! I travel often for work and need secure access to important documents and emails. This VPN has never let me down. The connection is always fast, and I feel protected wherever I am. It’s the best one I’ve used so far.'
                }
              />

              <Testimonial
                flag={argentina}
                rate={5}
                user={'Carlos F. (Argentina)'}
                content={
                  'I’ve been using OrbVPN for a few months now, and it’s been rock solid. The security is top-notch, and I never experience any slowdowns while using it. Plus, the interface is really user-friendly, so setting it up was a breeze. Great job!'
                }
              />

              <Testimonial
                flag={india}
                rate={5}
                user={'Anika M. (India)'}
                content={
                  'OrbVPN gives me peace of mind when I’m online. The app is really easy to use, and it works flawlessly across my devices. I love that I can safely browse and shop without worrying about hackers. Excellent product with great support!'
                }
              />

              <Testimonial
                flag={portugal}
                rate={5}
                user={'Jorge V. (Portugal)'}
                content={
                  'OrbVPN has been a game-changer for me. I travel a lot, and this VPN keeps me safe on public networks without any hassle. The connection speed is excellent, and I don’t have to worry about my data being compromised. Highly satisfied!'
                }
              />

              <Testimonial
                flag={norway}
                rate={5}
                user={'Sara K. (Norway)'}
                content={
                  "I've used a few VPNs before, but OrbVPN is by far the best. It’s fast, secure, and the app is so simple to navigate. I also appreciate the responsive customer service whenever I had a question. I feel so much safer using the internet now."
                }
              />

              <Testimonial
                flag={egypt}
                rate={5}
                user={'Ahmed G. (Egypt)'}
                content={
                  'I wanted a VPN that wouldn’t slow down my connection, and OrbVPN delivers on that. It’s perfect for streaming, and I can browse securely without worrying about who’s watching. I’m very happy with the performance so far!'
                }
              />

              <Testimonial
                flag={japan}
                rate={5}
                user={'Kaito N. (Japan)'}
                content={
                  'OrbVPN works flawlessly for my needs. I mainly use it for gaming and streaming, and there’s no noticeable drop in speed. It’s easy to install, and I feel my personal information is much more secure online now. A great investment!'
                }
              />

              <Testimonial
                flag={new_zealand}
                rate={5}
                user={'Olivia T. (New Zealand)'}
                content={
                  'I’ve been using OrbVPN for about a year, and it’s been incredibly reliable. I love that I can use it on multiple devices at once, and it’s perfect for keeping my browsing private. Definitely one of the best VPN services out there!'
                }
              />

              <Testimonial
                flag={italy}
                rate={5}
                user={'Luca M. (Italy)'}
                content={
                  'I’ve been using OrbVPN for several months now, and it’s been fantastic. It keeps my online activity private, and the connection speeds are great, even when I’m streaming in HD. I feel a lot more secure knowing my data is protected.'
                }
              />

              <Testimonial
                flag={turkey}
                rate={5}
                user={'Yasmin A. (Turkey)'}
                content={
                  'OrbVPN has been a great addition to my daily internet usage. I like how easy it is to connect and switch between servers. The speeds are fast, and I don’t experience any buffering. It’s a solid VPN that I trust for my online privacy.'
                }
              />

              <Testimonial
                flag={denmark}
                rate={5}
                user={'Jonas S. (Denmark)'}
                content={
                  'What I appreciate most about OrbVPN is how seamless it is. I forget it’s even running because there’s no lag or issues. It’s reassuring to know my data is encrypted and safe, especially when I’m doing sensitive work or using public Wi-Fi.'
                }
              />

              <Testimonial
                flag={singapore}
                rate={5}
                user={'Priya N. (Singapore)'}
                content={
                  'I wasn’t sure if I needed a VPN until I started using OrbVPN. It’s been so easy to use, and I can really feel the difference in security when I’m browsing. It doesn’t slow down my connection at all, which is a huge plus for me!'
                }
              />

              <Testimonial
                flag={brazil}
                rate={5}
                user={'Pedro C. (Brazil)'}
                content={
                  'OrbVPN has really impressed me with how fast and reliable it is. I use it across all my devices, and it’s been smooth sailing. I feel so much better knowing my personal information is protected every time I go online.'
                }
              />

              <Testimonial
                flag={iran}
                rate={5}
                user={'Ali R. (Iran)'}
                content={
                  'OrbVPN has been great for ensuring my privacy online. It’s easy to use, and the connection is fast and stable. I especially like how secure it feels when accessing websites or apps, knowing that my data is protected.'
                }
              />
            </div>
            <div className={styles.savebg}>
              <img
                src={vWidth > 700 ? savebg : savebg2}
                alt='landing background'
              />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.happyend}>
        <Wrapper>
          <GetStarted />
          <div className={styles.sun}>
            <img src={sun} alt='Sun Smiling' />
          </div>
          <div className={styles.happyendWrapper}>
            <div className={styles.happyendContent}>
              <h1 className={styles.contentHeader}>
                Download the <span className={styles.brand}>OrbVPN</span> Now!
              </h1>
              <p className={styles.plead}>
                Join thousands of users worldwide who trust OrbVPN for secure,
                private browsing.
              </p>
              <div className={styles.action}>
                <small className={styles.actionL}>
                  Enjoy lightning-fast speeds and protect your personal data
                  with ease. Download now and unlock a safer, unrestricted
                  online experience.
                </small>
                <Link to='/' className={styles.actionBtn}>
                  Get OrbVPN
                </Link>
                <small className={styles.actionS}>
                  we never store or share your IP address, ensuring complete
                  anonymity
                </small>
              </div>
            </div>
          </div>
          <div className={styles.showcaseBottom}>
            <img src={happyend} alt='something happened' />
          </div>
        </Wrapper>
      </section>
    </>
  );
};

export default HomeScreens;
