import React, { useState, useLayoutEffect } from 'react';
import Wrapper from '../../components/UI/Wrapper/Wrapper';
import googleplay from '../../components/assets/icons/googleplay.svg';
import applesbadge from '../../components/assets/icons/applesbadge.svg';
import macbadge from '../../components/assets/icons/macbadge.svg';
// import microsoftbadge from '../../components/assets/icons/microsoftbadge.svg';
import rocketp1 from '../../components/assets/technology/Rocket-p1.svg';
import rocketp2 from '../../components/assets/technology/Rocket-p2.svg';
import rocketp3 from '../../components/assets/technology/Rocket-p3.svg';
import rocketp4 from '../../components/assets/technology/Rocket-p4.svg';
import rocketp5 from '../../components/assets/technology/Rocket-p5.svg';
import rocketp6 from '../../components/assets/technology/Rocket-p6.svg';
import rocketp7 from '../../components/assets/technology/Rocket-p7.svg';

import GetStarted from '../../components/UI/GetStartedBtn/GetStarted';

import styles from './TechnologyScreen.module.css';

const TechnologyScreen = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={styles.backParallax}
        style={{ transform: `translateY(${offsetY * 0.66}px)` }}
      ></div>
      <div className={styles.frontParallax}>
        <div className={styles.secionWrapper}>
          <section className={styles.section}>
            <Wrapper>
              <GetStarted />
              <div className={styles.techWrapper}>
                <div className={styles.techContent}>
                  <h2 className={`${styles.contentHeader}`}>
                    OrbVPN's Technology
                  </h2>
                  <div className={styles.rocketParts}>
                    <img
                      src={rocketp1}
                      alt='OrbVPN Technology'
                      className={styles.pr40}
                    />
                  </div>
                  <p className={styles.worldendp}>
                    We have developed OrbSSL to be used for our users. Secure
                    Sockets Layer (SSL)-based VPN is an emerging technology that
                    provides remote access connectivity from almost any
                    Internet-enabled location using a Web browser and its native
                    SSL encryption.
                  </p>
                  <p className={styles.worldendp}>
                    OrbVPN protects your privacy and identity, health, finance,
                    family data with military-grade encryption browsing.
                  </p>
                  <p className={styles.worldendp}>
                    We encrypt all your data and keep no records of your
                    activities online.
                  </p>
                  <p className={styles.worldendp}>
                    Cybercriminals regularly monitor unencrypted hotspots to
                    catch soft targets, and any private information you send
                    over a public network may be available for anyone to view
                    and/or steal.
                  </p>
                  <p className={styles.worldendp}>
                    By hiding your IP address, neither hackers nor your ISP will
                    be able to see and keep track of your online activities.
                  </p>
                  <p className={styles.worldendp}>
                    Moreover, your device will be protected against all kinds of
                    malware.
                  </p>
                </div>
              </div>
            </Wrapper>
          </section>
          <section className={styles.section}>
            <Wrapper>
              <GetStarted />
              <div className={styles.techWrapper}>
                <div className={styles.techContent}>
                  <h2 className={`${styles.contentHeader}`}>OrbVPN Desktop</h2>
                  <div className={styles.rocketParts}>
                    <img
                      src={rocketp2}
                      alt='OrbVPN Desktop'
                      className={styles.pr40}
                    />
                  </div>
                  <p className={styles.worldendp}>
                    OrbVPN is a powerful internet security and privacy solution
                    for your computer.
                  </p>
                  <p className={styles.worldendp}>
                    OrbVPN encrypts data sent and received over the Internet
                    protecting your personal data, financial, passwords,
                    downloads, health and messages.
                  </p>
                  <p className={styles.worldendp}>
                    We create a private internet tunnel not accessible to
                    hackers.
                  </p>
                  <p className={styles.worldendp}>
                    OrbVPN allows you to browse and access geo-blocked websites
                    as well as navigate anonymously.
                  </p>
                  <p className={styles.worldendp}>
                    OrbVPN is available for Windows, MacOS and Linux, download
                    it now and takea dvantage of our 30-day money-back
                    guarantee.
                  </p>
                </div>
              </div>
            </Wrapper>
          </section>
          <section className={styles.section}>
            <Wrapper>
              <GetStarted />
              <div className={styles.techWrapper}>
                <div className={styles.techContent}>
                  <h2 className={`${styles.contentHeader}`}>OrbVPN Mobile</h2>
                  <div className={styles.rocketParts}>
                    <img src={rocketp3} alt='OrbVPN Mobile' />
                  </div>
                  <p className={styles.worldendp}>
                    Any time you use your Mobile device on public networks or
                    Wi-Fi, you are exposed to multiple security threats.
                  </p>
                  <p className={styles.worldendp}>
                    Hackers scan these networks to capture unencrypted data
                    while it's being transferred. Without you even noticing, you
                    are making your credit card data along with other sensitive
                    information available to hackers. Having a trusted VPN will
                    prevent these attacks on your private data.
                  </p>
                  <p className={styles.worldendp}>
                    OrbVPN provides you with a safe and private tunnel for your
                    internet connections even when you connect to public Wi-Fi.
                  </p>
                </div>
              </div>
            </Wrapper>
            {/* </section>
          <section className={styles.section}>
            <Wrapper>
              <GetStarted />
              <div className={styles.techWrapper}>
                <div className={styles.techContent}>
                  <h2 className={`${styles.contentHeader}`}>OrbVPN Plugins</h2>
                  <div className={styles.rocketParts}>
                    <img src={rocketp4} alt='OrbVPN Plugins' />
                  </div>
                  <p className={styles.worldendp}>
                    When browsing the web using a public Wi-Fi hotspot, you are
                    exposing yourself to security threats.
                  </p>
                  <p className={styles.worldendp}>
                    Hackers tend to use these Wi-Fi networks to get your
                    personal and private data, making you a victim of identity
                    theft.
                  </p>
                  <p className={styles.worldendp}>
                    Using OrbVPN in your Chrome, Safari or Firefox, you will get
                    protection against online security threats.
                  </p>
                  <p className={styles.worldendp}>
                    Using an end-to-end encrypted tunnel makes it impossible for
                    hackers to steal your data when using a Wi-Fi Hotspot.
                  </p>
                </div>
              </div>
            </Wrapper>
          </section> */}
          </section>
          <section className={styles.section}>
            <Wrapper>
              <GetStarted />
              <div className={styles.techWrapper}>
                <div className={styles.techContent}>
                  <h2 className={`${styles.contentHeader}`}>
                    Multi-Protocol Technology
                  </h2>
                  <div className={styles.rocketParts}>
                    <img src={rocketp4} alt='Multi-Protocol' />
                  </div>
                  <p className={styles.worldendp}>
                    OrbVPN supports a range of advanced protocols to give you
                    the best online experience, whether you're browsing,
                    streaming, or securing sensitive data.
                  </p>
                  <p className={styles.worldendp}>
                    Our multi-protocol technology includes WireGuard, Cisco,
                    VLESS, and Reality protocols, ensuring maximum flexibility
                    and performance, no matter your connection needs.
                  </p>
                  <p className={styles.worldendp}>
                    Whether you’re aiming for speed, security, or bypassing
                    restrictions, OrbVPN has the right protocol for you, backed
                    by cutting-edge encryption to keep your data safe.
                  </p>
                  <p className={styles.worldendp}>
                    Stay protected with end-to-end encryption using OrbVPN’s
                    multi-protocol support, even when using public Wi-Fi or
                    shared networks.
                  </p>
                </div>
              </div>
            </Wrapper>
          </section>

          <section className={styles.section}>
            <Wrapper>
              <GetStarted />
              <div className={styles.techWrapper}>
                <div className={styles.techContent}>
                  <h2 className={`${styles.contentHeader}`}>
                    Benefits of Using OrbVPN
                  </h2>
                  <div className={styles.rocketParts}>
                    <img src={rocketp5} alt='Benefits of Using OrbVPN' />
                  </div>
                  <p className={styles.worldendp}>
                    OrbVPN enables access to websites and social networks,
                    sports, audio, and video streaming subject to location-based
                    IP blocking.
                  </p>
                  <p className={styles.worldendp}>
                    Moreover, some governments put location-based restrictions
                    on websites, and content services may also restrict access.
                  </p>
                  <p className={styles.worldendp}>
                    OrbVPN for mobile devices lets you bypass location-based IP
                    blocking and enjoy the Internet unrestricted.
                  </p>
                  <p className={styles.worldendp}>
                    OrbVPN bypasses Internet filters and firewalls set by your
                    network administrator, giving you access to all of the
                    uncensored content you want.
                  </p>
                </div>
              </div>
            </Wrapper>
          </section>
          <section className={styles.section}>
            <Wrapper>
              <GetStarted />
              <div className={styles.techWrapper}>
                <div className={styles.techContent}>
                  <h2 className={`${styles.contentHeader}`}>Hide your IP</h2>
                  <div className={styles.rocketParts}>
                    <img src={rocketp6} alt='Hide your IP' />
                  </div>
                  <p className={styles.worldendp}>
                    Many internet users are asking how they can change their IP
                    address.
                  </p>
                  <p className={styles.worldendp}>
                    OrbVPN allow internet users to change their IP address,
                    helping them to avoid content blocks and IP bans around the
                    web.
                  </p>
                  <p className={styles.worldendp}>
                    Download OrbVPN now and take advantage of our 30-day
                    money-back guarantee.
                  </p>
                </div>
              </div>
            </Wrapper>
          </section>
        </div>
        <section className={styles.lastScreen}>
          <Wrapper>
            <GetStarted />
            <div className={styles.downloadWrapper}>
              <div className={styles.downloadContent}>
                <h2 className={`${styles.contentHeader}`}>
                  Download the <span className={styles.brand}>OrbVPN</span> Now!
                </h2>
                <div className={styles.rocketParts}>
                  <img src={rocketp7} alt='Download the OrbVPN' />
                </div>
                <p className={styles.worldendp}>
                  Join tousands of users already enjoying Internet Freedom, high
                  grade of data protection and online security around the world.
                </p>
                <br />
                <br />
                <div className={styles.badges}>
                  <a href='https://apps.apple.com/us/app/orbvpn/id6727010164'>
                    <img src={applesbadge} alt='Download from apple store' />
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=com.orbvpn.android&hl=en_US&gl=US&pli=1'>
                    <img src={googleplay} alt='Download from google play' />
                  </a>
                  <a href='https://apps.apple.com/us/app/orbvpn/id6727010164'>
                    <img src={macbadge} alt='Download for your Mac' />
                  </a>
                  {/* <a href='https://play.google.com/store/apps/details?id=com.orb_vpn&hl=en_US&gl=US&pli=1'>
                    <img src={microsoftbadge} alt='Download for your windows' />
                  </a> */}
                </div>
              </div>
            </div>
          </Wrapper>
        </section>
      </div>
    </div>
  );
};

export default TechnologyScreen;
